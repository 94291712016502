import React, { Suspense } from "react";
import "./../styles/global.scss";

import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import GoogleIcon from "../assets/google_maps.svg";
import { Switch, Route, Router } from "./../util/router.js";
import ISPLogo from "../assets/Logo.jpeg";
import Loader from "../components/Loader";

import MapImage from "../assets/MapImage.png";
const IndexPage = React.lazy(() => import("./index"));
const ResultsPage = React.lazy(() => import("./results"));
const TestimonialsPage = React.lazy(() => import("./testimonials"));
const CoursesPage = React.lazy(() => import("./courses"));

const Gallery = React.lazy(() => import("./gallery"));
const MBoss = React.lazy(() => import("./mboss"));
const Faculties = React.lazy(() => import("./faculties"));
const News = React.lazy(() => import("./news"));
const NotFoundPage = React.lazy(() => import("./not-found.js"));
const ContactSection = React.lazy(() =>
  import("./../components/ContactSection")
);
const FloatingContact = React.lazy(() =>
  import("../components/FloatingContact")
);
// import HallOfFame from "./hallOfFame";

const App = (props) => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <FloatingContact />
        <Navbar color="white" spaced={true} logo={ISPLogo} />

        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/results" component={ResultsPage} />
          <Route exact path="/testimonials" component={TestimonialsPage} />
          <Route exact path="/courses" component={CoursesPage} />
          <Route exact path="/mboss" component={MBoss} />
          <Route exact path="/faculties" component={Faculties} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/news" component={News} />

          {/* <Route exact path="/hall-of-fame" component={HallOfFame} /> */}
          <Route component={NotFoundPage} />
        </Switch>

        <ContactSection
          color="white"
          size="large"
          backgroundImage=""
          backgroundImageOpacity={1}
          title="Contact Us"
          element={
            <div className="columns has-text-centered ">
              <a
                href="https://www.google.com/maps/place/Janki+Residency/@19.2970764,72.8492503,15z/data=!4m5!3m4!1s0x0:0x49f096c16e4571d7!8m2!3d19.297805!4d72.85246"
                target="_blank"
                rel="noreferrer noopener"
                className="column map_container"
              >
                {" "}
                <img
                  className="image is-96x96 is-inline-block"
                  alt="IITian's Science Point"
                  src={GoogleIcon}
                />
                <div className="title is-size-5 my-2">Bhayander West</div>
                <div className="has-text-weight-bold is-size-6">
                  Shop-01, Janki Residency CHS LTD, Next to Walchand Complex, 90
                  Feet Road
                </div>
              </a>
              <a
                href="https://www.google.com/maps/place/IITians+Science+Point/@19.31074,72.8569367,18z/data=!4m5!3m4!1s0x0:0x77717aa77fcbeffb!8m2!3d19.310782!4d72.856986"
                target="_blank"
                rel="noreferrer noopener"
                className="column map_container"
              >
                <img
                  className="image is-96x96 is-inline-block"
                  alt="IITian's Science Point"
                  src={GoogleIcon}
                />{" "}
                <div className="title is-size-4 my-2">Bhayander East - HO</div>
                <div className="has-text-weight-bold is-size-6">
                  1st Floor, Forging Estate, Beside Mother Mary's College (Rahul
                  Education) Navghar Road Bhayander, Maharashtra 401105 India
                </div>
              </a>
              <a
                href="https://www.google.com/maps/place/SPECTRUM+ACADEMIA/@19.2894668,72.8688412,15z/data=!4m5!3m4!1s0x0:0x4edb2b80508b50af!8m2!3d19.2894668!4d72.8688412"
                target="_blank"
                rel="noreferrer noopener"
                className="column map_container"
              >
                <img
                  className="image is-96x96 is-inline-block"
                  alt="IITian's Science Point"
                  src={GoogleIcon}
                />
                <div className="title is-size-5 my-2">Mira Road</div>
                <div className="has-text-weight-bold is-size-6">
                  Kanakia Rd, Shivar Garden, Mira Road, Mira Bhayandar,
                  Maharashtra 401107
                </div>
              </a>
            </div>
          }
          subtitle="We strive to make our customers happy! And no, we didn't know about the similarly titled movie. Please stop asking about that."
          buttonText="Send message"
          buttonColor="primary"
          buttonInverted={false}
          showNameField={false}
          embedSrc={MapImage}
        />
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
