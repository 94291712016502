import React, { useState } from "react";
import { Link } from "./../util/router.js";
// import { useAuth } from "./../util/auth.js";

function Navbar(props) {
  // const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav
      className={
        "navbar" +
        (props.color ? ` is-${props.color}` : "") +
        (props.spaced ? " is-spaced" : "")
      }
    >
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item py-3 ml-5 ">
            <Link to="/">
              <img
                className="image"
                style={{ transform: "scale(2.2)" }}
                src={props.logo}
                alt="Logo"
              />
            </Link>
          </div>
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/">
              Home
            </Link>
            <Link className="navbar-item" to="/faculties">
              Faculties
            </Link>
            <Link className="navbar-item" to="/results">
              Results
            </Link>
            <Link className="navbar-item" to="/courses">
              Courses
            </Link>
            <Link className="navbar-item" to="/mboss">
              MBOSS
            </Link>
            <Link className="navbar-item" to="/testimonials">
              Testimonials
            </Link>
            <Link className="navbar-item" to="/news">
              News
            </Link>

            {/* <Link className="navbar-item" to="/hall-of-fame">
              Hall of Fame
            </Link>  */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
