import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="container__footer">
      <div className="isp-pvt-limited">
        IITians Science Point is a brand registered under IITians Science Point
        Private Limited.
      </div>
      <div>
        <a
          href="https://ishaancodes.web.app/"
          target="_blank"
          rel="noreferrer noopener"
          className="u-center-content container__footer_text"
        >
          {" "}
          Website made with{" "}
          <span className="container__footer_text_heart">&#x2764;</span> by{" "}
          <span className="ishaan_parmar">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="container__footer_text_developer"
              href="https://ishaancodes.web.app/"
            >
              Ishaan Parmar
            </a>
          </span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
